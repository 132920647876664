export type MenuItemType = {
    key: string;
    label: string;
    isTitle?: boolean;
    icon?: string;
    url?: string;
    badge?: {
        variant: string;
        text: string;
    };
    parentKey?: string;
    target?: string;
    children?: MenuItemType[];
};

const MENU_ITEMS: MenuItemType[] = [
    { key: 'navigation', label: 'Neo Saúde', isTitle: true },
    {
        key: 'dashboards',
        label: 'Analytics',
        isTitle: false,
        icon: 'uil-home-alt',
        // badge: { variant: 'success', text: '1' },
        children: [

            {
                key: 'ds-ecommerce',
                badge: { variant: 'danger', text: 'Demo' },
                label: 'Summary',
                url: '/dashboard/summary',
                parentKey: 'dashboards',
            }
        ],
    },
    {
        key: 'rpm',
        label: 'RPM',
        isTitle: false,
        icon: 'mdi mdi-medical-bag',
        // badge: { variant: 'success', text: '1' },
        children: [

            {
                key: 'rpm-patients',
                label: 'Patients',
                url: '/rpm/patients',
                parentKey: 'rpm',
            }
        ],
    },
    { key: 'apps', label: 'Manage', isTitle: true },
    {
        key: 'apps-calendar',
        //label: 'Calendar',//appointment
        badge: { variant: 'danger', text: 'Demo' },
        label: 'Appointments',//
        isTitle: false,
        icon: 'uil-calender',
        url: '/apps/calendar',
    },
    {
        key: 'apps-chat',
        badge: { variant: 'danger', text: 'Demo' },
        label: 'Chat',
        isTitle: false,
        icon: 'uil-comments-alt',
        url: '/apps/chat',
    },
    {
        key: 'apps-crm',
        //label: 'CRM',//Assistants
        badge: { variant: 'danger', text: 'Demo' },
        label: 'Management',//Assistants
        isTitle: false,
        // badge: { variant: 'danger', text: 'New' },
        icon: 'uil-tachometer-fast',
        children: [
            {
                key: 'crm-management',
                //label: 'Management',//Assistants
                label: 'Medical Staff',//
                url: '/apps/crm/management',
                parentKey: 'apps-crm',
            },
        ],
    },
    {
        key: 'apps-projects',
        // label: 'Projects',//Journeys
        badge: { variant: 'danger', text: 'Demo' },
        label: 'Health Campaigns',//Journeys
        isTitle: false,
        icon: 'uil-briefcase',
        children: [
            {
                key: 'project-list',
                label: 'Campaigns List',
                url: '/apps/projects/list',
                parentKey: 'apps-projects',
            },
            {
                key: 'project-details',
                label: 'Details',
                url: '/apps/projects/details',
                parentKey: 'apps-projects',
            },
            {
                key: 'project-create-project',
                // label: 'Create Project',
                label: 'Create Campaign',
                url: '/apps/projects/new',
                parentKey: 'apps-projects',
            },
        ],
    },
    {
        key: 'apps-file-manager',
        badge: { variant: 'danger', text: 'Demo' },
        label: 'File Manager',
        isTitle: false,
        icon: 'uil-folder-plus',
        url: '/apps/file',
    },

    { key: 'custom', label: 'Custom', isTitle: true },
    {
        key: 'pages',
        label: 'Account',
        isTitle: false,
        icon: 'uil-user',
        children: [
            {
                key: 'page-profile',
                label: 'Profile',
                url: '/pages/profile',
                parentKey: 'pages',
            }
        ],
    },
];

export { MENU_ITEMS };
