import { MedicalSpeciality } from 'pages/RPM/PatientProfile/Comments/types';
import { MedicalHistoryActionTypes } from './constants';

export type MedicalHistoryActionType = {
    type:
    | MedicalHistoryActionTypes.CHANGE_HISTORY_STATUS;
    payload: {
        status: MedicalSpeciality,
    };
};

export const changeHistoryStatus = (history: { status: MedicalSpeciality }): MedicalHistoryActionType => ({
    type: MedicalHistoryActionTypes.CHANGE_HISTORY_STATUS,
    payload: history,
});