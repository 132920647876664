import React, { Suspense } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import { DefaultLayout, VerticalLayout, HorizontalLayout, DetachedLayout, FullLayout } from 'layouts';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import { LayoutTypes } from 'appConstants';
import { useRedux } from 'hooks';
import { activeConfig } from '../config/clientConfig';
import FeatureFlag from '../components/FeatureFlag/FeatureFlag';
const TermsAndConditions = React.lazy(() => import('pages/account/TermsAndConditions'));
const PrivacyPolicy = React.lazy(() => import('pages/account/PrivacyPolicy'));

// lazy load all the views

// auth
const Login = React.lazy(() => import('pages/account/Login'));
const Logout = React.lazy(() => import('pages/account/Logout'));
const Register = React.lazy(() => import('pages/account/Register'));
const Confirm = React.lazy(() => import('pages/account/Confirm'));
const ForgetPassword = React.lazy(() => import('pages/account/ForgetPassword'));
/* const LockScreen = React.lazy(() => import('pages/account/LockScreen')); */
const ResetPassword = React.lazy(() => import('pages/account/ResetPassword'));
const ResetPasswordSuccess = React.lazy(() => import('pages/account/ResetPasswordSuccess'));

const Confirm2 = React.lazy(() => import('pages/account/Confirm2'));
/* const LockScreen2 = React.lazy(() => import('pages/account/LockScreen2')); */

// dashboard
const EcommerceDashboard = React.lazy(() => import('pages/dashboard/Ecommerce'));

// apps
const sistemsAlertDashboard = React.lazy(() => import('pages/apps/SistemsAlerts/'));

// - file
// const FileManager = React.lazy(() => import('pages/apps/FileManager'));

// pages
const Profile = React.lazy(() => import('pages/profile'));
const ErrorPageNotFound = React.lazy(() => import('pages/error/PageNotFound'));
const ErrorPageNotFoundAlt = React.lazy(() => import('pages/error/PageNotFoundAlt'));
const ServerError = React.lazy(() => import('pages/error/ServerError'));

// - other
const Maintenance = React.lazy(() => import('pages/other/Maintenance'));

// myHealth
const MyHealthOverview = React.lazy(() => import('pages/myHealth/Overview'));

// RPM
const PatientList = React.lazy(() => import('pages/RPM/Patients'));
const PatientOverview = React.lazy(() => import('pages/RPM/PatientOverview'));
const NewPatient = React.lazy(() => import('pages/RPM/NewPatient'));
const PatientProfile = React.lazy(() => import('pages/RPM/PatientProfile'));
const AlertDashboard = React.lazy(() => import('pages/RPM/Alerts'));
const EmergenciesDashboard = React.lazy(() => import('pages/RPM/Emergencies'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const { appSelector } = useRedux();
    const authProtectedRoutes: RouteObject[] = [];
    const authPublicRoutes: RouteObject[] = [];

    const { layout } = appSelector((state) => ({
        layout: state.Layout,
    }));

    const getLayout = () => {
        let layoutCls: React.ComponentType = VerticalLayout;

        switch (layout.layoutType) {
            case LayoutTypes.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case LayoutTypes.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case LayoutTypes.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    if (activeConfig.routes.myHealth) {
        authProtectedRoutes.push({
            path: 'myHealth',
            children: [
                {
                    path: 'overview',
                    element: <FeatureFlag name="overview">
                        <LoadComponent component={MyHealthOverview} />
                    </FeatureFlag>

                },
            ],
        });
    }

    if (activeConfig.routes.dashboard) {
        authProtectedRoutes.push({
            path: 'dashboard',
            children: [
                {
                    path: 'summary',
                    element: <FeatureFlag name="summary">
                        <LoadComponent component={EcommerceDashboard} />
                    </FeatureFlag>

                },
            ],
        });
    }

    if (activeConfig.routes.rpm) {
        authProtectedRoutes.push({
            path: 'rpm',
            children: [
                {
                    path: 'alerts',
                    element: <FeatureFlag name="alerts">
                        <LoadComponent component={AlertDashboard} />
                    </FeatureFlag>
                },
                {
                    path: 'emergencies',
                    element: <FeatureFlag name="emergencies">
                        <LoadComponent component={EmergenciesDashboard} />
                    </FeatureFlag>
                },
                {
                    path: 'patients',
                    element: <FeatureFlag name="patients">
                        <LoadComponent component={PatientList} />
                    </FeatureFlag>
                },
                {
                    path: 'newPatient',
                    element: <FeatureFlag name="newPatient">
                        <LoadComponent component={NewPatient} />
                    </FeatureFlag>
                },
                {
                    path: 'patientProfile/:patientName/:patientID/:tab',
                    element: <FeatureFlag name="patientProfile">
                        <LoadComponent component={PatientProfile} />
                    </FeatureFlag>
                },
                {
                    path: 'patientOverview/:patientName/:patientID',
                    element: <FeatureFlag name="patientOverview">
                        <LoadComponent component={PatientOverview} />
                    </FeatureFlag>
                },
            ],
        });
    }

    if (activeConfig.routes.apps) {
        authProtectedRoutes.push({
            path: 'apps',
            children: [
                {
                    path: 'notifications',
                    children: [
                        {
                            path: 'systemAlerts/',
                            element: <FeatureFlag name="systemAlerts">
                                <LoadComponent component={sistemsAlertDashboard} />
                            </FeatureFlag>
                        },
                    ],
                },
            ],
        });
    }

    if (activeConfig.routes.pages) {
        authProtectedRoutes.push({
            path: 'pages',
            children: [
                {
                    path: 'error-404-alt',
                    element: <LoadComponent component={ErrorPageNotFoundAlt} />
                },
                {
                    path: 'profile',
                    element: <FeatureFlag name="profile">
                        <LoadComponent component={Profile} />
                    </FeatureFlag>
                },
            ],
        });
    }


    authPublicRoutes.push({
        path: 'account',
        children: [
            { path: 'login', element: <LoadComponent component={Login} /> },
            {
                path: 'register', element: <FeatureFlag name="register">
                    <LoadComponent component={Register} />
                </FeatureFlag>
            },
            { path: 'confirm', element: <LoadComponent component={Confirm} /> },
            { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
            /* { path: 'lock-screen', element: <LoadComponent component={LockScreen} /> }, */
            { path: 'logout', element: <LoadComponent component={Logout} /> },
            { path: 'confirm2', element: <LoadComponent component={Confirm2} /> },
            /* { path: 'lock-screen2', element: <LoadComponent component={LockScreen2} /> }, */
            { path: 'reset-password', element: <LoadComponent component={ResetPassword} /> },
            { path: 'reset-password-success', element: <LoadComponent component={ResetPasswordSuccess} /> },
            {
                path: 'error-404',
                element: <LoadComponent component={ErrorPageNotFound} />
            },
            {
                path: 'error-500',
                element: <LoadComponent component={ServerError} />
            },
            {
                path: 'maintenance',
                element: <LoadComponent component={Maintenance} />
            },
            {
                path: 'terms', element: <FeatureFlag name="termsAndConditions">
                    <LoadComponent component={TermsAndConditions} />
                </FeatureFlag>
            },
            {
                path: 'privacy', element: <FeatureFlag name="termsAndConditions">
                    <LoadComponent component={PrivacyPolicy} />
                </FeatureFlag>
            },
        ],
    });

    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: authPublicRoutes,
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={'Admin'} component={Layout} />,
            children: authProtectedRoutes,
        },

    ]);
};

export { AllRoutes };
