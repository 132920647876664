import { CampaignActionType } from "./actions";
import { CampaignActionTypes, CampaignStateType } from "./constants";

const INIT_STATE: CampaignStateType = {
    name: '',
    uuid: ''
};

const Campaign = (state = INIT_STATE, action: CampaignActionType) => {
    switch (action.type) {
        case CampaignActionTypes.CHANGE_CAMPAIGN:
            return {
                ...state,
                name: action.payload.name,
                uuid: action.payload.uuid
            };

        default:
            return { ...state };
    }
}

export default Campaign;