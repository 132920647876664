import { APICore } from './apiCore';
import { activeConfig } from 'config/clientConfig';

const api = new APICore();
const BASE_URL = `/${activeConfig.api.DOMAIN}/`;

enum MeasurementType {
    Temperature = 'Temperature',
    Weight = 'Weight',
    Height = 'Height',
    Hemoglobin = 'Hemoglobin',
    OxygenSaturation = 'Oxygen saturation',
    Glucose = 'Glucose',
    Cholesterol = 'Cholesterol',
    UricAcid = 'Uric Acid',
    Lactate = 'Lactate',
    Triglycerides = 'Triglycerides',
    Ketone = 'Ketone',
    BloodPressure = 'Blood Pressure',
    HeartBeats = 'Heart Beats'
}

interface MeasurementParams {
    uuid_user: string;
    uuid_edit: string;
    month: string;
    year: string;
}

interface MeasurementPayload extends MeasurementParams {
    type_measurement: MeasurementType;
}

function getMeasurement(params: MeasurementParams, type: MeasurementType) {
    const payload: MeasurementPayload = {
        ...params,
        type_measurement: type,
    };
    return api.create(`${BASE_URL}getMeasurement/`, payload);
}

const getTemps = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Temperature);
const getWeights = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Weight);
const getHeights = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Height);
const getHemoglobins = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Hemoglobin);
const getOxygenSaturations = (params: MeasurementParams) => getMeasurement(params, MeasurementType.OxygenSaturation);
const getGlucose = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Glucose);
const getCholesterol = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Cholesterol);
const getUricAcid = (params: MeasurementParams) => getMeasurement(params, MeasurementType.UricAcid);
const getLactate = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Lactate);
const getTriglycerides = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Triglycerides);
const getKetone = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Ketone);
const getBloodPressure = (params: MeasurementParams) => getMeasurement(params, MeasurementType.BloodPressure);
const getHeartbeats = (params: MeasurementParams) => getMeasurement(params, MeasurementType.HeartBeats);

interface LastMeasurementsParams {
    uuid_user: string;
    month: string;
    year: string;
    uuid_edit: string;
}

function getLastMeasurements(params: LastMeasurementsParams) {
    return api.create(`${BASE_URL}getLastMeasurements/`, params);
}

interface AddMeasurementParams {
    uuid_user: string;
    value: string;
    uuid_device: string;
    measurement_date: string;
    type_measurement: string;
    uuid_edit: string;
}

function addMeasurement(params: AddMeasurementParams) {
    return api.create(`${BASE_URL}addMeasurement/`, params);
}

interface UpdateParams {
    uuid_user: string;
    measurementId: string;
    statusCurrent: string;
    comment: string;
}

function updateMeasurement(params: UpdateParams) {
    const payload = {
        uuid_user: params.uuid_user,
        measurement_id: parseFloat(params.measurementId),
        status_measurement: params.statusCurrent,
        comment: params.comment,
    };
    return api.create(`${BASE_URL}UpdateMeasurement/`, payload);
}

function updateNotification(params: UpdateParams) {
    const payload = {
        uuid_user: params.uuid_user,
        measurement_id: parseFloat(params.measurementId),
        status_measurement: params.statusCurrent,
        comment: params.comment,
    };
    return api.create(`${BASE_URL}UpdateNotification/`, payload);
}

export {
    MeasurementType,
    getMeasurement,
    addMeasurement,
    getTemps,
    getHeights,
    getWeights,
    getOxygenSaturations,
    getGlucose,
    getBloodPressure,
    getCholesterol,
    getUricAcid,
    getLactate,
    getTriglycerides,
    getKetone,
    getLastMeasurements,
    getHeartbeats,
    getHemoglobins,
    updateMeasurement,
    updateNotification
};